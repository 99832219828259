@charset "UTF-8";

.aroot{
    width: '10%';
    margin: "auto";
    overflow-X: 'auto';
}
.admin-input {
    width: '100%';
    border-Radius: 4;
    border: '1px solid #ced4da';
    background-Color: '#FDFDFD';
    padding: '3px 10px';
}
.admin-form {
    padding: 30;
}
.admin-addButton {
    margin: "15px";
    width: '50%';
    color: '#2F4F4F';
    border: '1px solid #2F4F4F';
    background-Color: '#FDFDFD';
    border-Radius: 4;
}
.admin-addDoubleButton {
    margin: "15px";
    width: '100%';
    color: '#2F4F4F';
    border: '1px solid #2F4F4F';
    background-Color: '#FDFDFD';
    border-Radius: 4;
}
.admin-deleteButton {
    color: '#C77373';
    background-Color: '#FDFDFD';
    border: '1px solid #C77373';
    border-Radius: 4;
}
.admin-cancelButton {
    color: '#2F4F4F';
    background-Color: '#FDFDFD';
    border: '1px solid #2F4F4F';
    border-Radius: 4;
}
.admin-button {
    color: '#FDFDFD';
    background-Color: '#2F4F4F';
    border-Radius: 4;
}
.admin-table {
    table-Layout: 'auto'
}
.admin-err {
    color: 'red'
}
.admin-header {
    padding-Left: '20px';
    padding-bottom: '5px';
    padding-Top:'5px';
    margin-Bottom: '10px';
    text-Align: 'left';
    width: '100%';
    background-Color: '#707070'; 
    border-Radius: 4;
    color: '#F8F8F8'
}
.admin-icon {
    margin: 'auto'
}